<template>
   <main class="bg-white">
      <section class="flex flex-col gap-4 w-8/12 m-auto py-16">
         <div class="text-center text-4xl font-bold mb-8">
            <span>Creez un nouvel article pour ediqia.</span>
         </div>

         <b-form @submit.prevent class="flex flex-col gap-8">
            <div class="flex flex-col gap-4 mb-2">
               <div class="flex flex-col">
                  <label for="">Titre de l'article</label>
                  <input
                     v-model="state.input.title"
                     type="text"
                     class="ouline-none bg-gray-50 p-1 rounded-md"
                     placeholder="Comment s'incrire sur ediqia ?"
                  />
                  <span
                     class="text-sm text-red-500 mt-[4px]"
                     v-if="state.error.path === 'title'"
                  >
                     {{ state.error.message }}
                  </span>
               </div>

               <div class="flex flex-col">
                  <label for="">Description</label>
                  <input
                     v-model="state.input.describle"
                     type="text"
                     class="ouline-none bg-gray-50 p-1 rounded-md"
                     placeholder="Donnez une description pour l'article..."
                  />
                  <span
                     class="text-sm text-red-500 mt-[4px]"
                     v-if="state.error.path === 'description'"
                  >
                     {{ state.error.message }}
                  </span>
               </div>

               <div class="flex flex-col">
                  <label for="">Url video (Youtube/Vimeo) </label>
                  <input
                     v-model="state.input.image"
                     type="text"
                     class="ouline-none bg-gray-50 p-1 rounded-md"
                     placeholder="https://unsplash.com/"
                  />
                  <span
                     class="text-sm text-red-500 mt-[4px]"
                     v-if="state.error.path === 'url'"
                  >
                     {{ state.error.message }}
                  </span>
               </div>

               <div class="flex flex-col">
                  <label for="">Category</label>
                  <select
                     v-model="state.input.category"
                     type="text"
                     class="ouline-none bg-gray-50 p-1 rounded-md"
                     placeholder="Selectionner une category"
                  >
                     <option
                        :value="xCategorie.id"
                        v-for="(xCategorie, index) in xCategories"
                        :key="index"
                     >
                        {{ xCategorie.libelle }}
                     </option>
                  </select>
                  <span
                     class="text-sm text-red-500 mt-[4px]"
                     v-if="state.error.path === 'category_id'"
                  >
                     {{ state.error.message }}
                  </span>
               </div>
            </div>

            <editors
               api-key="lazk7gg5oms6zgs448g62ewr44k6dbs9gerzidtpfzg5p67o"
               :init="{
                  selector: 'textarea',
                  plugins:
                     'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage tinycomments tableofcontents footnotes mergetags autocorrect',
                  toolbar:
                     'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                  tinycomments_mode: 'embedded',
                  tinycomments_author: 'Author name',
                  mergetags_list: [
                     { value: 'First.Name', title: 'First Name' },
                     { value: 'Email', title: 'Email' },
                  ],
               }"
               v-model="contentBlog"
            />

            <div class="flex gap-4">
               <div class="">
                  <button
                     :disabled="state.loading ? true : false"
                     v-if="!state.isUpdate"
                     @click="createdArticle"
                     class="bg-purple-900 text-white p-1 rounded"
                  >
                     {{ state.loading ? 'Loading...' : "Enregistre l'article" }}
                  </button>
                  <button
                     :disabled="state.loading ? true : false"
                     v-if="state.isUpdate"
                     @click="updateArticle"
                     class="bg-purple-900 text-white p-1 rounded"
                  >
                     {{
                        state.loading
                           ? 'Loading...'
                           : 'Sauvegader les modification'
                     }}
                  </button>
               </div>

               <div v-if="state.isUpdate">
                  <button
                     @click="cancelUpdate"
                     class="bg-purple-300 text-white p-1 rounded"
                  >
                     Annuler la modification
                  </button>
               </div>
            </div>
         </b-form>
      </section>

      <section class="w-11/12 m-auto pb-32">
         <div class="py-[16px] border-t">
            <span class="text-2xl font-bold">Liste des articles</span>
         </div>

         <div class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <div v-for="(item, index) in articles" :key="index">
               <a
                  :href="'https://ediqia.com/blog/' + item.id"
                  class="flex items-center gap-2 relative border-[0.5px] rounded-md p-[16px] cursor-pointer"
               >
                  <!-- Content info of articles -->
                  <div class="w-full flex flex-col gap-2">
                     <!-- owner articles -->
                     <div class="flex items-center gap-1">
                        <div class="w-6 h-6 rounded-full bg-slate-100">
                           <img
                              class="rounded-full"
                              :src="item.user.profile_photo_url"
                              alt=""
                           />
                        </div>
                        <span class="text-xs font-bold">
                           {{ item.user.nom }}
                        </span>
                     </div>
                     <!-- Content info of articles  -->
                     <div class="flex flex-col gap-4">
                        <span class="text-3xl font-bold break-words">
                           {{ item.title }}
                        </span>
                        <span class="text-sm break-words">
                           {{ item.description }}
                        </span>
                     </div>
                  </div>

                  <!-- Articles of image -->
                  <!-- <div class="w-5/12 h-[80px] rounded-md bg-slate-200">
                     <img
                        class="w-full h-full object-cover rounded-md"
                        :src="item.url"
                        alt=""
                     />
                     
                  </div>-->
               </a>

               <div class="grid grid-cols-2 gap-2 p-[4px] w-full">
                  <div
                     @click.stop="deleteBlog(item.id)"
                     class="text-xs text-center bg-red-300 rounded text-white p-[4px] cursor-pointer"
                  >
                     Supprimer
                  </div>
                  <div
                     @click.stop="updateBlog(item)"
                     class="text-xs text-center bg-blue-300 rounded text-white p-[4px] cursor-pointer"
                  >
                     Modifer
                  </div>
               </div>
            </div>
         </div>
      </section>
   </main>
</template>

<script>
import {
   BRow,
   BCol,
   BModal,
   BTable,
   BPagination,
   BFormInput,
   BFormGroup,
   BButton,
   VBModal,
   BForm,
   BLink,
   BFormCheckbox,
   BInputGroup,
   BInputGroupAppend,
   BImg,
   BFormTextarea,
   BBadge,
   VBToggle,
   BFormDatepicker,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import vSelect from 'vue-select';
import URL from '@/views/request';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { computed } from '@vue/composition-api';
import { Editor, EditorContent } from '@tiptap/vue-2';
import ListItem from '@tiptap/extension-list-item';
import Image from '@tiptap/extension-image';
import StarterKit from '@tiptap/starter-kit';
import axios from 'axios';
import { reactive, onMounted, ref } from '@vue/composition-api';
import { data } from 'vue-echarts';
import setAuthHeader from '../../../auth/jwt/token';
import Editors from '@tinymce/tinymce-vue';
import VueFroala from 'vue-froala-wysiwyg';

export default {
   components: {
      editors: Editors,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BModal,
      BTable,
      BPagination,

      BFormGroup,
      BForm,
      BImg,
      BLink,
      BForm,
      BFormCheckbox,
      BInputGroup,
      BFormTextarea,
      BInputGroupAppend,
      BBadge,
      EditorContent,
      setAuthHeader,
   },
   directives: {
      Ripple,
      'b-toggle': VBToggle,
   },

   setup(props, { root }) {
      const editor = ref();
      const xCategories = ref([]);
      const articles = ref([]);
      const contentBlog = ref('');

      const state = reactive({
         loading: false,
         isUpdate: false,
         idBlog: null,
         input: {
            title: '',
            describle: '',
            image: '',
            category: '',
         },
         error: {
            path: '',
            message: '',
         },
      });

      onMounted(() => {
         getCategory();
         getAllArticles();
      });

      async function getCategory() {
         try {
            const { data } = await axios.get(URL.lIST_CATEGORY_BLOG);

            if (data) {
               xCategories.value = data;
            }
         } catch (error) {
            console.log(error);
         }
      }

      const validateForm = async (req) => {
         let error = null;
         const arrayKey = Object.keys(req);
         const arrayContent = Object.values(req);

         for (let i = 0; i < arrayKey.length; i++) {
            const el = arrayKey[i];
            if (arrayContent[i] === '') {
               error = {
                  path: el,
                  message: el + ' ne dois pas être vide.',
               };
               return { error };
            }
         }

         return { error };
      };

      const createdArticle = async () => {
        
         state.loading = true;
         try {
            const dataArticles = {
               title: state.input.title,
               description: state.input.describle,
               content: contentBlog.value,
               url: state.input.image,
               category_id: state.input.category,
            };

            const { error } = await validateForm(dataArticles);
            console.log(error);
            if (error) {
               state.error.path = error.path;
               state.error.message = error.message;
               return 'is error';
            }

            const { data } = await axios.post(URL.CREATED_BLOG, dataArticles);
            if (data) {
               articles.value.push(data.article);
               state.input.title = '';
               state.input.describle = '';
               state.input.image = '';
               state.input.category = '';

               state.error.path = '';
               contentBlog.value = ''
               state.loading = false;
            }
         } catch (error) {
            state.loading = false;
            console.log(error);
         }
      };

      const updateArticle = async () => {
         state.loading = true;
         try {
            const dataArticles = {
               id: state.idBlog,
               title: state.input.title,
               description: state.input.describle,
               content: contentBlog.value,
               url: state.input.image,
               category_id: state.input.category,
            };

            const { error } = await validateForm(dataArticles);
            console.log(error);
            if (error) {
               state.error.path = error.path;
               state.error.message = error.message;
               return 'is error';
            }

            const { data } = await axios.post(URL.UPDATE_BLOG, dataArticles);
            if (data) {
               articles.value.forEach((el) => {
                  if (el.id === state.idBlog) {
                     el.title = dataArticles.title;
                     el.description = state.input.describle;
                     el.url = state.input.image;
                     el.content = contentBlog.value;
                     el.category_id = state.input.category;
                  }
               });
               state.input.title = '';
               state.input.describle = '';
               state.input.image = '';
               state.input.category = '';

               state.error.path = '';
               state.isUpdate = false;
               contentBlog.value = ''
               state.loading = false;
            }
         } catch (error) {
            state.loading = false;
            console.log(error);
         }
      };

      async function getAllArticles() {
         try {
            state.loading = true;
            const { data } = await axios.get(URL.LIST_BLOG);

            if (data) {
               articles.value = data;
               articles.value.forEach((el) => {
                  el.title =
                     el.title.substring(0, 50) +
                     `${el.title.length > 50 ? '...' : ''}`;
                  el.description =
                     el.description.substring(0, 70) +
                     `${el.description.length > 70 ? '...' : ''}`;
               });
               state.loading = false;
            }
         } catch (error) {
            console.log(error);
            state.loading = false;
         }
      }

      const deleteBlog = async (idBlog) => {
         try {
            const dataArticles = {
               id: idBlog,
            };

            const { data } = await axios.post(URL.DELETE_BLOG, dataArticles);
            if (data) {
               articles.value = articles.value.filter((item) => {
                  return item.id !== idBlog;
               });
            }
         } catch (error) {
            console.log(error);
         }
      };

      const updateBlog = async (blog) => {
         state.isUpdate = true;
         state.idBlog = blog.id;
         state.input.title = blog.title;
         state.input.describle = blog.description;
         state.input.image = blog.url;
         state.input.category = blog.category_id;
         contentBlog.value = ''(blog.content);
      };

      const cancelUpdate = () => {
         state.isUpdate = false;
         state.input.title = '';
         state.input.describle = '';
         state.input.image = '';
         state.input.category = '';

         state.error.path = '';
         contentBlog.value = ''
      };

      return {
         contentBlog,
         createdArticle,
         state,
         editor,
         xCategories,
         articles,
         deleteBlog,
         updateBlog,
         updateArticle,
         cancelUpdate,
      };
   },
};
</script>

<style>
.ProseMirror ul {
   list-style-type: disc !important;
   padding-left: 2rem /* 20px */;
}

.ProseMirror > * + * {
   margin-top: 0.75em;
}

.ProseMirror img {
   width: 100%;
   height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   line-height: 1.1;
}

h1 {
   @apply text-5xl;
}
h2 {
   @apply text-4xl;
}
h3 {
   @apply text-3xl;
}
h4 {
   @apply text-2xl;
}
h5 {
   @apply text-xl;
}
h6 {
   @apply text-lg;
}

button,
.otherBtn {
   border: 1px solid black;
   padding: 4px;
}

li {
   @apply list-outside;
}

.ProseMirror {
   outline: none;
}
</style>
